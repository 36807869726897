import {EventEmitter, Injectable, OnDestroy, SecurityContext} from '@angular/core';
import {
  Box3,
  Camera,
  Color,
  ColorRepresentation,
  CylinderGeometry, DataTexture,
  DirectionalLight,
  DoubleSide,
  Group,
  HemisphereLight,
  Light,
  Material,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  OrthographicCamera,
  PCFSoftShadowMap,
  PerspectiveCamera,
  PlaneGeometry,
  Raycaster,
  RepeatWrapping,
  RingGeometry,
  Scene,
  ShaderMaterial,
  ShadowMaterial,
  Sphere,
  Sprite,
  Texture,
  Vector2,
  Vector3,
  WebGLRenderer,
  XRTargetRaySpace,
} from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {Configuration, RenderState} from '@ess/jg-rule-executor';
import {AttachmentPointInfo} from '@src/app/model/attachment-point-info';
import {NoConfigurationLoadedError} from '@src/app/model/errors';
import {PlacementService} from '@src/app/services/placement/placement.service';
import {ModelLoadingService} from '@src/app/services/model-loading/model-loading.service';
import {ApplicationStateService} from '@src/app/services/applicationstate/application-state.service';
import {takeUntil} from 'rxjs/operators';
import {ApplicationState} from '@src/app/model/applicationstate';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DEBUG} from '@src/app/constants';
import {ViewMode} from '@src/app/model/view-mode';
import {EditPointInfo} from '@src/app/model/edit-point-info';
import {SpriteData} from '@src/app/model/sprite-data';
import {AttachmentPointService} from '@src/app/services/attachment-point/attachment-point.service';
import {InitDataService} from '@src/app/services/data/init-data.service';
import {CSS2DObject, CSS2DRenderer} from 'three/examples/jsm/renderers/CSS2DRenderer';
import {GUI} from 'dat.gui';
import {LoggingService} from '@src/app/services/logging/logging.service';
import {ARButton} from 'three/examples/jsm/webxr/ARButton';
import {EventListener} from 'three/src/core/EventDispatcher';
import {JGARUserData} from '@src/app/services/render/jg-ar-user-data';
import {USDZExporter} from 'three/examples/jsm/exporters/USDZExporter';
import {IframeService} from '@src/app/services/iframe/iframe.service';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {GroundedSkybox} from "three/examples/jsm/objects/GroundedSkybox";

@Injectable({
  providedIn: 'root',
})
export class RenderService implements OnDestroy {
  private _OFFSET: number = 10; // offset trees-config and trees-border
  private _TREE_SCALE: number = 0.8; // Set scale of the tree
  private _TREE_AMOUNT: number = 0; // amount of trees to be placed (per tree kind)
  private _GRASS_AMOUNT: number = 0; // amount of trees to be placed (per tree kind)

  set treeOffset(offset: number) {
    this._OFFSET = offset;
  }

  set treeAmount(amount: number) {
    this._TREE_AMOUNT = amount;
  }

  set treeScale(scale: number) {
    this._TREE_SCALE = scale;
  }

  set grassAmount(amount: number) {
    this._GRASS_AMOUNT = amount;
  }

  public clickedAttachmentPoint: EventEmitter<{
    info: AttachmentPointInfo,
    config: Configuration,
    logClickGA?: boolean
  }> = new EventEmitter<{ info: AttachmentPointInfo, config: Configuration }>();
  public clickedEditPoint: EventEmitter<{
    info: EditPointInfo,
    config: Configuration,
    logClickGA?: boolean
  }> = new EventEmitter<{ info: EditPointInfo, config: Configuration }>();

  public triggerMeasurementRefresh: EventEmitter<any> = new EventEmitter<any>();

  public readonly renderInfo: Subject<{ memory: object, render: object }> = new Subject<{
    memory: object,
    render: object
  }>();
  public readonly animating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _maxCameraDistance: number = 15;
  private _cylinderFactor: number = 1.5;
  private _freedomSphereCenter: Vector3 = new Vector3(0, 0, 0);
  private _freedomSphere: Sphere = new Sphere(this._freedomSphereCenter, this._maxCameraDistance);
  private _floorSize: number = 2 * this._maxCameraDistance * this._cylinderFactor;
  private _serviceDestroyed$: Subject<void> = new Subject();
  private _readyToRender: boolean = false;
  private _renderer: WebGLRenderer;
  private _labelRenderer: CSS2DRenderer;
  private _usdzExporter: USDZExporter;
  private _scene: Scene = new Scene();
  private _topScene: Scene = new Scene();
  private _perspectiveCamera: PerspectiveCamera = new PerspectiveCamera();
  private _orthographicCamera: OrthographicCamera = new OrthographicCamera();
  private _activeCamera: PerspectiveCamera | OrthographicCamera = this._perspectiveCamera; //default
  private _topCamera: OrthographicCamera = new OrthographicCamera();
  private _controls: OrbitControls;
  private _screenshotClearing: number = 0.4;
  // These types are explicitly checked for the size
  private _floor: Mesh<PlaneGeometry, Material>;
  private _shadowFloor: Mesh<PlaneGeometry, ShadowMaterial>;
  private _cylinder: Mesh<CylinderGeometry, Material>;
  private _trees: Group[];
  private _grass: Group[];
  private _skyboxTexture: DataTexture;
  private _skyboxTextureLocation: string = 'https://jg-static-content.s3.eu-central-1.amazonaws.com/configurator/Football-Field-Imotski-4K.hdr';
  private _skybox: GroundedSkybox;
  private _skyboxParams= {
    height: 4,
    radius: 150,
    enabled: true,
  };
  private _backgroundGroup: Group = new Group();
  private _lights: Light[] = [];
  private _lightHelpers: Object3D[] = []; // For debugging purposes
  private _rayCaster = new Raycaster();
  private _pointer = new Vector2(0, 0);
  private _loadedConfiguration: Configuration | null = null;
  private _selectedAttachmentPoint: Sprite | null = null;
  private _selectedEditPoint: Sprite | null = null;
  private _loadedConfigurationGroup: Group | null = null;
  private _loadedMarkerGroup: Group | null = null;
  private _loadedSpriteGroup: Group | null = null;
  private _topSceneSpriteGroup: Group | null = null;
  private _viewMode: ViewMode = ViewMode.loading;
  public startup: boolean = true;
  private _viewModeChange$: BehaviorSubject<ViewMode> = new BehaviorSubject<ViewMode>(this._viewMode);
  // will only be set by the interactionService, when the measurementButton in the menu is clicked.
  public showMeasurements: boolean = false;
  private _arrowLineGroup: Group | null = null;
  // GUI for editing some settings, only defined if needed (debugging purposes)
  private _gui: GUI;

  // AR
  public ARAvailable: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public ARActive: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  private _arActive: boolean = false;
  private _rotationRate: number = 200;
  private _rotatingThresholdMs: number = 200;
  private _arUserData: JGARUserData;
  private _xrTargetRaySpace: XRTargetRaySpace;
  private _surfaceSelector: Mesh;
  private _currentHitTestSource: XRHitTestSource;
  private _hitTestSourceRequested: boolean;
  private _rememberedRenderState: RenderState;
  private _preRenderBackgroundColor: Color | Texture;

  public spinnerOnSubject$: Subject<void> = new Subject<void>();
  public spinnerOffSubject$: Subject<void> = new Subject<void>();

  constructor(
    private _placementService: PlacementService,
    private _attachmentPointService: AttachmentPointService,
    private _modelLoadingService: ModelLoadingService,
    private _dataService: InitDataService,
    private _loggingService: LoggingService,
    private _iframeService: IframeService,
    private _translateService: TranslateService,
    private _sanitizer: DomSanitizer,
    // private _debugService: DebugService, // Uncomment for debugging
    stateService: ApplicationStateService,
  ) {
    stateService.getState$()
      .pipe(takeUntil(this._serviceDestroyed$))
      .subscribe(s => {
        switch (s) {
          case ApplicationState.init: {
            // reset all data
            this._readyToRender = false;
            break;
          }
          case ApplicationState.configAssetsLoaded: {
            this._readyToRender = true;
            break;
          }
          default: {
            // do nothing
            break;
          }
        }
      });
    // Set up floor and lights
    this._backgroundGroup.name = 'background';
    this.setBackground(
      this._dataService?.currentRetailerOptions?.colorCodes.gradient2,
      this._dataService?.currentRetailerOptions?.colorCodes.gradient1,
      this._dataService?.currentRetailerOptions?.colorCodes.gradient2,
    );
    this._setUpLights();
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._serviceDestroyed$.next();
    this._serviceDestroyed$.complete();
  }

  public setViewMode(newViewMode: ViewMode): void {
    this._viewMode = newViewMode;
    this._viewModeChange$.next(this._viewMode);
  }

  public getViewMode$(): Observable<ViewMode> {
    return this._viewModeChange$;
  }

  public getViewModeValue(): ViewMode {
    return this._viewModeChange$.getValue();
  }

  /**
   * Gets the DOM element from the renderer.
   */
  public getDomElement(): HTMLCanvasElement {
    return this._renderer.domElement;
  }

  /**
   * Gets the scene
   */
  public getScene(): Scene {
    return this._scene;
  }

  /**
   * Refresh
   */
  public refreshScene(): void {
    this._triggerSingleAnimationPing(null);
    this._setTopCameraConfig();
  }

  private _downloadUsdzBlob(blob: Blob): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'configuration.usdz';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  public async downloadUsdzScene(): Promise<void> {
    if (!this._usdzExporter) {
      this._usdzExporter = new USDZExporter();
    }

    // Load configuration to scene an extra time because sometimes it's not there for some weird reason
    await this.loadConfigurationToScene(this._loadedConfiguration, true);

    // Clone initial scene and remove background etc.
    const exportScene: Scene = this._scene.clone(true);
    const toRemove: Object3D[] = [];
    exportScene.traverse(o => {
      if (['background', 'shadowfloor', 'floor', 'cylinder'].includes(o?.name)) {
        toRemove.push(o);
      }
    });
    exportScene.remove(...toRemove);
    // Now export and download
    this._usdzExporter.parse(exportScene,
      dataArray => this._downloadUsdzBlob(new Blob([dataArray], {type: 'application/octet-stream'})),
      e => {
        console.log(e);
      });
  }

  /**
   * Gets the currently loaded configuration
   */
  public getCurrentConfiguration(): Configuration {
    if (this._loadedConfiguration) {
      this._loadedConfiguration.renderState = this._getRenderState();
      return this._loadedConfiguration;
    } else {
      throw new NoConfigurationLoadedError();
    }
  }

  /**
   * Updates the currently loaded configuration after a save
   */
  public updateCurrentConfiguration(name: string, email: string): Configuration {
    if (this._loadedConfiguration) {
      this._loadedConfiguration.name = name;
      this._loadedConfiguration.email = email;
      return this._loadedConfiguration;
    } else {
      throw new NoConfigurationLoadedError();
    }
  }

  /**
   * Retrieve selected point, regardless of whether it's an AttachmentPoint or an EditPoint
   */
  public getSelectedInfo(): AttachmentPointInfo | EditPointInfo | undefined {
    return this.getSelectedAttachmentPointInfo() ? this.getSelectedAttachmentPointInfo() : this.getSelectedEditPointInfo();
  }

  /**
   * Retrieve data for the currently selected AttachmentPoint
   */
  public getSelectedAttachmentPointInfo(): AttachmentPointInfo | undefined {
    if (this._selectedAttachmentPoint && SpriteData.fromSprite(this._selectedAttachmentPoint)) {
      return AttachmentPointInfo.fromSprite(this._selectedAttachmentPoint);
    } else {
      return undefined;
    }
  }

  /**
   * Retrieve data for the currently selected EditPoint
   */
  public getSelectedEditPointInfo(): EditPointInfo | undefined {
    if (this._selectedEditPoint && SpriteData.spriteHasData(this._selectedEditPoint)) {
      return EditPointInfo.fromSprite(this._selectedEditPoint);
    } else {
      return undefined;
    }
  }

  /**
   * Check if immersive AR is supported
   */
  public async browserHasImmersiveArCompatibility(): Promise<boolean> {
    // This does not work well in an iframe and is thus disabled JG-899
    if (window.navigator.xr && !this._iframeService.inIframe()) {
      return await navigator.xr.isSessionSupported(
        'immersive-ar',
      );
    }
    return false;
  }

  public constructTeardownAR(tearDown: boolean = false): void {
    let selectedOnce = false;
    const selectStartListener: EventListener<{ data: XRInputSource }, 'selectstart', XRTargetRaySpace> =
      function (e: { data: XRInputSource } & { type: 'selectstart' } & { target: XRTargetRaySpace }) {
        this._arUserData.startTime = performance.now();
        this._arUserData.isSelecting = true;
        this._arUserData.startPosition = this._xrTargetRaySpace.position;
        this._arUserData.lastPosition = this._arUserData.startPosition;
      }.bind(this);
    const selectEndListener: EventListener<{ data: XRInputSource }, 'selectend', XRTargetRaySpace> =
      function (e: { data: XRInputSource } & { type: 'selectend' } & { target: XRTargetRaySpace }) {
        this._arUserData.isSelecting = false;
      }.bind(this);
    this._arActive = !tearDown;
    this.ARActive.next(this._arActive);
    if (!tearDown) {
      // Save current render state to reset to after AR is done
      this._rememberedRenderState = this._getRenderState().clone();
      // Setup stuff for hit testing
      this._currentHitTestSource = null;
      this._hitTestSourceRequested = false;
      const self = this;

      function onSelect() {
        if (self._surfaceSelector.visible) {
          if (!selectedOnce) {
            selectedOnce = true;
            self._loadedConfigurationGroup.visible = true;
          }
          self._loadedConfigurationGroup.position.setFromMatrixPosition(self._surfaceSelector.matrix);
        }
      }

      this._xrTargetRaySpace = this._renderer.xr.getController(0);
      this._xrTargetRaySpace.addEventListener('select', onSelect);
      this._xrTargetRaySpace.userData.starttime = undefined;
      this._xrTargetRaySpace.userData.scrollthresholdms = 200;
      this._arUserData = this._xrTargetRaySpace.userData as JGARUserData;

      this._xrTargetRaySpace.addEventListener('selectstart', selectStartListener);
      this._xrTargetRaySpace.addEventListener('selectend', selectEndListener);

      // Add controller
      this._scene.add(this._xrTargetRaySpace);
      // Remove background
      this._backgroundGroup.removeFromParent();
      this._preRenderBackgroundColor = this._scene.background.clone();
      this._scene.background = undefined;
      // Hide config before first select
      this._loadedConfigurationGroup.visible = false;
      // This seems weird as we already have a render loop from the canvas component, but this is OK as this one only triggers when AR is started
      this._renderer.setAnimationLoop(this._arRender.bind(this));
    } else {
      // Reset
      this._renderer.setAnimationLoop(undefined);
      this._currentHitTestSource = null;
      this._hitTestSourceRequested = false;
      this._scene.background = this._preRenderBackgroundColor.clone();
      this._preRenderBackgroundColor = undefined;
      // Remove target from scene and reset render state
      this._scene.remove(this._xrTargetRaySpace);
      const config = this.getCurrentConfiguration().clone();
      config.renderState = this._rememberedRenderState;
      // Load configuration to scene
      this.loadConfigurationToScene(config, true);
    }
  }

  /**
   * Sets up the renderer for given canvas
   */
  public initializeRenderer(canvasSelector: string): void {
    this._renderer = new WebGLRenderer({
      powerPreference: 'high-performance',
      canvas: document.querySelector(canvasSelector),
      antialias: true,
      alpha: true,
    });
    // Set colorSpace to old default (changed in a three.js update, the new default makes the wood/shadows look different)
    this._renderer.outputColorSpace = 'srgb-linear';

    // AR Setup if possible
    this.browserHasImmersiveArCompatibility().then(available => {
      this.ARAvailable.next(available);
      if (available) {
        // Enable XR for AR
        this._renderer.xr.enabled = true;
        // Setup when a session starts and teardown when it ends
        this._renderer.xr.addEventListener('sessionstart', () => this.constructTeardownAR());
        this._renderer.xr.addEventListener('sessionend', () => this.constructTeardownAR(true));

        // Add AR button to the ar instruction modal and hide it
        const arModal = document.getElementById('ar-instruction-modal');
        const arButton = ARButton.createButton(this._renderer, {
          requiredFeatures: ['hit-test'],
        });
        // Make sure we can identify the button
        arButton.setAttribute('id', 'ARBUTTON');
        arButton.style.setProperty('visibility', 'hidden');
        arModal.appendChild(arButton);
      }
    });

    this._labelRenderer = new CSS2DRenderer();
    this._labelRenderer.setSize(window.innerWidth, window.innerHeight);
    this._labelRenderer.domElement.style.position = 'absolute';
    this._labelRenderer.domElement.style.top = '60px';
    this._labelRenderer.domElement.style.zIndex = '0';

    document.body.appendChild(this._labelRenderer.domElement);
    // this, combined with alpha: true, makes the top scene transparent
    this._renderer.autoClear = false;
    this._renderer.setPixelRatio(window.devicePixelRatio);
    // We need to set the shadowMap to true to see shadows
    this._renderer.shadowMap.enabled = true;
    this._renderer.shadowMap.type = PCFSoftShadowMap;

    this._setupCameraAndControls();
  }

  public correctLabelTop(): void {
    this._labelRenderer.domElement.style.top = '0';
    this._setupCameraAndControls();
  }

  private _setupCameraAndControls(orthographic: boolean = false): void {
    if (!orthographic) {
      this._perspectiveCamera = new PerspectiveCamera();
      // Also set the orbitControls
      this._controls = new OrbitControls(this._perspectiveCamera, this._labelRenderer.domElement);
      // polar angle is fixed
      this._controls.minPolarAngle = 2 * Math.PI / 180;
      this._controls.maxPolarAngle = 90 * Math.PI / 180;
      this._controls.maxDistance = this._maxCameraDistance;
      this._controls.target.setY(2);
      // Set active camera
      this._activeCamera = this._perspectiveCamera;
      // Setup control listeners
      this._setUpControlListener();
    } else {
      this._orthographicCamera = new OrthographicCamera();
      this._renderer.setSize(window.innerWidth, window.innerHeight);
      this._renderer.setPixelRatio(window.devicePixelRatio);
      // Set active camera
      this._activeCamera = this._orthographicCamera;
      // Disable all shadows to avoid confusion and unclear images
      this._lights.forEach(l => l.castShadow = false);
      // No control listeners as this camera is not allowed to move
    }
  }

  /**
   * AR Render function.
   * Note that this is only called when AR is active
   */
  private _arRender(time: DOMHighResTimeStamp, frame: XRFrame): void {
    if (frame) {
      if (!this._hitTestSourceRequested) {
        this._requestHitTestSource();
      }
      if (this._currentHitTestSource) {
        this._getHitTestResults(frame);
      }
    }
    this._handleRotation();
    this._renderer?.render(this._scene, this._activeCamera);
  }

  private _handleRotation() {
    if (this._arUserData.isSelecting) {
      this._arUserData.isRotating = performance.now() - this._arUserData.startTime > this._rotatingThresholdMs;
      if (this._arUserData.isRotating) {
        this._arUserData.lastPosition = this._xrTargetRaySpace.position;
        this._loadedConfigurationGroup.rotateY(Math.PI / this._rotationRate);
      }
    }
  }

  /**
   * Starts the scene by adding lights, camera and action.
   */
  public initializeScene(setCamera: boolean = true): void {
    this._scene.clear();
    this._topScene.clear();
    switch (this._viewMode) {
      case ViewMode.edit:
        // load floor and cylinder
        this.setBackground(
          this._dataService.currentRetailerOptions?.colorCodes?.gradient2,
          this._dataService.currentRetailerOptions?.colorCodes?.gradient1,
          this._dataService.currentRetailerOptions?.colorCodes?.gradient2,
        );
        break;
      case ViewMode.preview:
        // Load the floor and sky with the textures if they exist, otherwise use the standard colors
        this.setBackground(0xe7f5fe, 0x87cefa, 0x16aa54, true);
        break;
      // In case of a screenshots, we set background to be white and all
      case ViewMode.cleanFront:
      case ViewMode.cleanSide:
      case ViewMode.cleanTop:
      case ViewMode.cleanNice:
        // Set white background and floor
        this.setBackground(0xffffff, 0xffffff, 0xffffff);
        break;
    }

    // load setup into scene
    this._scene.add(...this._lights, ...this._lightHelpers, this._backgroundGroup);

    if (setCamera) {
      this._setCameraConfig(this._loadedConfiguration);
      this._perspectiveCamera.rotateX(Math.PI / 8);
    }
    this._setTopCameraConfig();

    document.addEventListener('click', this._onClick.bind(this));
    window.addEventListener('resize', this._triggerSingleAnimationPing.bind(this));

    this._surfaceSelector = new Mesh(
      new RingGeometry(0.15, 0.2, 32).rotateX(-Math.PI / 2),
      new MeshBasicMaterial(),
    );
    this._surfaceSelector.matrixAutoUpdate = false;
    this._surfaceSelector.visible = false;
    this._scene.add(this._surfaceSelector);
  }

  /**
   * Checks if a place is hit and updates accordingly
   */
  private _requestHitTestSource(): void {
    const session = this._renderer.xr.getSession();
    session.requestReferenceSpace('viewer').then(function (space) {
      session.requestHitTestSource({space}).then(function (source) {
        this._currentHitTestSource = source;
      }.bind(this));
    }.bind(this));
    session.addEventListener('end', function () {
      this._hitTestSourceRequested = false;
      this._currentHitTestSource = null;
    }.bind(this));
    this._hitTestSourceRequested = true;
  }

  /**
   * Gets the hitTestResult of the current frame.
   */
  private _getHitTestResults(frame: XRFrame): void {
    const hitTestResults = frame.getHitTestResults(this._currentHitTestSource);
    if (hitTestResults.length && !this._arUserData.isRotating) {
      const referenceSpace = this._renderer.xr.getReferenceSpace();
      const hit = hitTestResults[0];
      const pose = hit.getPose(referenceSpace);
      this._surfaceSelector.visible = true;
      this._surfaceSelector.matrix.fromArray(pose.transform.matrix);
    } else if (!hitTestResults.length) {
      this._surfaceSelector.visible = false;
    }
  }

  /**
   * Sets appropriate values for _topCamera
   */
  private _setTopCameraConfig(): void {
    this._topCamera.left = -this._renderer.domElement.clientWidth / 2.;
    this._topCamera.right = this._renderer.domElement.clientWidth / 2.;
    this._topCamera.top = this._renderer.domElement.clientHeight / 2.;
    this._topCamera.bottom = -this._renderer.domElement.clientHeight / 2.;
    this._topCamera.position.set(0, 0, 1);
    this._topCamera.lookAt(new Vector3());
  };

  /**
   * Triggers rendering of the scene(s)
   * Note; use active camera here to accommodate screenshots
   */
  public renderScene(): void {
    this._controls.update();
    this._renderer.clear();
    this._renderer.render(this._scene, this._activeCamera);
    this._renderer.clearDepth();
    this._renderer.render(this._topScene, this._topCamera);
    this._labelRenderer.render(this._scene, this._activeCamera);
    this._labelRenderer.render(this._topScene, this._topCamera);
  }

  /**
   * Updates render size
   * Note; only updates perspective camera as this is not a thing for orthographic cameras
   */
  public updateRenderSize(width: number, height: number): void {
    // you must pass false here or three.js sadly fights the browser
    this._renderer.setSize(width, height, false);
    this._labelRenderer.setSize(width, height);
    this._perspectiveCamera.aspect = width / height;
    this._setTopCameraConfig(); // update topCamera
    this._perspectiveCamera.updateProjectionMatrix();
    this._topCamera.updateProjectionMatrix();
    // update any render target sizes here
  }

  /**
   * Returns the current aspect ratio of the camera.
   */
  public getPerspectiveCameraAspectRatio(): number {
    return this._perspectiveCamera.aspect;
  }

  /**
   * Loads a configuration into the scene as a group of 3d objects, fire-and-forget style.
   * Starts by checking if all 3D models required are present, and retrieving them if necessary.
   * TODO: spinner on 'load' still needs to be implemented in the calling class
   *
   * setCamera determines if the camera position and rotation should be reset. (this will be the configuratorSettings or the default values)
   * See setCameraConfig for the implementations
   */
  public async loadConfigurationToScene(configuration: Configuration, setCamera: boolean): Promise<void> {
    if (this._readyToRender) {
      const configObjects = await this._placementService.createObjectForPlacement(
        configuration.configurationPlacement,
        this._viewMode === ViewMode.edit,
      );

      this._clearScene();
      this._scene.add(configObjects.modules);
      const objectBox = this.getTotalObjectBox();
      configObjects.markers.traverse(m => m.position.setY(objectBox.max.y));
      this._scene.add(configObjects.markers);
      this._scene.add(configObjects.sprites);
      this.triggerMeasurementRefresh.emit();

      this._loadedConfiguration = configuration;
      this._loadedConfigurationGroup = configObjects.modules;
      this._loadedMarkerGroup = configObjects.markers;
      this._loadedSpriteGroup = configObjects.sprites;
      this._loadedSpriteGroup.visible = false;

      this._topSceneSpriteGroup = configObjects.sprites.clone();
      this._topSceneSpriteGroup.traverse((obj) => {
        if (obj instanceof Sprite) {
          const apx = SpriteData.fromSprite(obj)?.isEditButton ?
            AttachmentPointService.EDIT_POINT_SIZES.x : AttachmentPointService.ATTACHMENT_POINT_SIZES.x;
          const apy = SpriteData.fromSprite(obj)?.isEditButton ?
            AttachmentPointService.EDIT_POINT_SIZES.y : AttachmentPointService.ATTACHMENT_POINT_SIZES.y;
          obj.scale.set(apx, apy, 1);
        }
      });
      this._topSceneSpriteGroup.visible = true;
      this._topScene.add(this._topSceneSpriteGroup);

      // We update the camera before we do the sprite positions, because otherwise we get temporarily incorrect visibility
      if (setCamera) {
        this._setCameraConfig(configuration);
      }

      this.setDisplaySpritePositions();
      if (!setCamera) {
        this._highlightSelectedPoint();
      }

      // When starting, add an animation around the sprites to make them pop
      if (this.startup) {
        this._addStartBubble(configuration);
      }

      if (this._viewMode === ViewMode.preview) {
        this._setUpGrass();
        this._setUpTrees();
      }
      // trigger animation / visual update
      this.refreshScene();
      // Do it again after short delay to combat timing issues in ThreeJs canvas and to apply after render functions.
      setTimeout(() => {
        configObjects.afterRender.forEach(f => f());
        this.refreshScene();
      }, 100);
    }
  }

  /**
   * Adds the text bubble displaying instructions, which is only visible until a visual attachment point is clicked
   */
  private _addStartBubble(config: Configuration) {
    const originPlacement = config.configurationPlacement.getOriginPlacement();
    const backgroundColour = 'var(--button)';
    const textColour = 'var(--background)';
    let positionFound = false;
    this._topSceneSpriteGroup.children.forEach(cc => {
      cc.children.forEach(child => {
        const spriteData = SpriteData.fromSprite(child as Sprite);

        // If it is a visible edit button on the origin placement
        if (spriteData?.modulePlacementId === originPlacement.id && !spriteData.isEditButton && child.visible && !positionFound) {
          positionFound = true;
          const textBubble = this._makeTextBubble(backgroundColour, textColour);
          const triangle = this._makeBubbleTriangle(backgroundColour);
          child.add(textBubble);
          child.add(triangle);
        }
      });
    });
  }

  private _makeTextBubble(backgroundColour: string, textColour: string): CSS2DObject {
    const h3 = document.createElement('h3');
    h3.className = 'startBubble';
    h3.textContent = this._translateService.instant('app.start.bubble');
    h3.style.backgroundColor = backgroundColour;
    h3.style.color = textColour;
    h3.style.marginTop = '50px';
    h3.style.padding = '16px';
    h3.style.borderRadius = '8px';
    h3.style.fontWeight = '700';
    h3.style.lineHeight = '21px';
    h3.style.fontSize = '16px';
    return new CSS2DObject(h3);
  }

  private _makeBubbleTriangle(colour: string): CSS2DObject {
    const div = document.createElement('div');
    div.style.borderStyle = 'solid';
    div.style.borderWidth = '0 10px 10px 10px';
    div.style.marginTop = '20px';
    div.style.transform = 'translateX(-50%)';
    div.style.borderColor = 'transparent transparent ' + colour + ' transparent';
    return new CSS2DObject(div);
  }

  /**
   * Function to toggle display of a given arrowGroup.
   *
   * @param showMeasurements if true and arrowGroup present: show arrows. Else: don't.
   * @param arrowGroup group containing arrow objects.
   */
  public toggleAndSetMeasurementArrows(showMeasurements: boolean, arrowGroup?: Group): void {
    if (showMeasurements && arrowGroup) {
      this.showMeasurements = true;
      this._arrowLineGroup = arrowGroup;
      this._scene.add(this._arrowLineGroup);
    } else {
      this.showMeasurements = false;
    }
    this.refreshScene();
  }

  public removeMeasurementArrows(): void {
    this._scene.remove(this._arrowLineGroup);
    this._arrowLineGroup?.children?.forEach(c => {
      if (c instanceof CSS2DObject) {
        c.element.remove();
      }
    });
    this._arrowLineGroup = null;
  }

  public unsetSelectedPoints(): void {
    this._selectedAttachmentPoint = null;
    this._selectedEditPoint = null;
  }

  /**
   * For every sprite in the top-scene s, find its corresponding 3dScene-target sprite, calculate the projection of that
   * sprite onto the top-scene, and set that projection as the position for s.
   * If the target is behind the camera, we hide it
   * If the target has an orientation and the orientation is outside the camera's "alignment zone", we also hide it.
   * Note that we use the perspective camera explicitly as this is not relevant for the orthographic camera
   */
  public setDisplaySpritePositions(): void {
    if (this._topSceneSpriteGroup) {
      this._topSceneSpriteGroup.traverse((s) => {
        if (s instanceof Sprite && SpriteData.spriteHasData(s)) {
          const target: Sprite = this._findTargetSprite(s);
          const orientation: Vector3 = SpriteData.fromSprite(target).orientation;
          if (
            (this._objectIsInCameraView(target, this._perspectiveCamera) &&
              (!orientation || this._orientationAlignsWithCamera(orientation, this._perspectiveCamera))) ||
            this._selectedAttachmentPoint?.id === s?.id ||
            this._selectedEditPoint?.id === s?.id
          ) {
            const position: Vector3 = this._findProjectionOfTargetOntoOrthographicCamera(target);
            s.position.set(position.x, position.y, position.z);
            s.visible = true;
          } else {
            s.position.setZ(10); // move it behind the camera
            s.visible = false;
          }
        }
      });
    }
  }

  /**
   * Given a sprite in _loadedSpriteGroup find matching topSpriteGroup-sprite (the target)
   * in lieu of a proper 'traverseFind' on groups.
   *
   * @private
   */
  private _findTargetSprite(s: Sprite): Sprite {
    let res: Sprite;
    this._loadedSpriteGroup.traverse(obj => {
      if (
        !res &&
        obj instanceof Sprite &&
        SpriteData.spriteHasData(obj) &&
        SpriteData.fromSprite(obj).equals(SpriteData.fromSprite(s))
      ) {
        res = obj;
      }
    });
    return res;
  }

  private _getCameraDirection(camera: Camera): Vector3 {
    return camera.getWorldDirection(new Vector3());
  }

  /**
   * Used to check if an object is in front of the camera (so that it can be rendered)
   *
   * @private
   */
  private _objectIsInCameraView(obj: Object3D, camera: Camera): boolean {
    const cameraDirection = this._getCameraDirection(camera);
    const objectDirection = new Vector3().subVectors(obj.position, camera.position);
    return cameraDirection.dot(objectDirection) >= 0;
  }

  /**
   * Determine whether the orientation is within the camera's "alignment zone".
   *
   * Alignment zone is any orientation that is either
   * 1) no more than 90 degrees (inclusive) away from the camera orientation, or
   * 2) directly up or down in the y-direction TODO: this might not be necessary?
   *
   * @param orientation orientation of the object to check
   * @param camera camera to check against
   * @private
   */
  private _orientationAlignsWithCamera(orientation: Vector3, camera: Camera): boolean {
    if (this._areVectorsClose(orientation, new Vector3(0, 1, 0)) || this._areVectorsClose(orientation, new Vector3(0, -1, 0))) {
      return true;
    } else {
      const cameraDirection = this._getCameraDirection(camera);
      return cameraDirection.dot(orientation) <= 0;
    }
  }

  /**
   * Calculates with a given margin if vectors are 'close'
   *
   * @param a The first vector
   * @param b The second vector
   * @param errorMargin The margin for each vector place
   * @private True iff the vectors are close
   */
  private _areVectorsClose(a: Vector3, b: Vector3, errorMargin: number = 0.01): boolean {
    return Math.abs(a.x - b.x) <= errorMargin && Math.abs(a.y - b.y) <= errorMargin && Math.abs(a.z - b.z) <= errorMargin;
  }

  /**
   * determine the projected location of a sprite in the 3D scene, as perceived by its own camera
   *
   * @private
   */
  private _findProjectionOfTargetOntoOrthographicCamera(sprite: Sprite): Vector3 {
    const position = new Vector3();
    sprite.updateMatrixWorld();
    position.setFromMatrixPosition(sprite.matrixWorld);
    position.project(this._perspectiveCamera);

    position.x *= (this._topCamera.right);
    position.y *= (this._topCamera.top);
    position.z = 0;

    return position;
  }

  /**
   * In the currently loaded configuration, figure out what selectable point is selected and trigger the 'selectX' call
   * Returns the currently selected sprite (if any)
   *
   * @private
   */
  private _highlightSelectedPoint(): Sprite | undefined {
    let returnVal;
    const selectedInfo: AttachmentPointInfo | EditPointInfo = this.getSelectedInfo();
    if (selectedInfo) {
      let expectedUserData: object;
      let callback: (x: Sprite) => void;
      switch (selectedInfo.constructor) {
        case AttachmentPointInfo:
          expectedUserData = {
            xAttachmentId: (selectedInfo as AttachmentPointInfo).xAttachmentId,
            modulePlacementId: (selectedInfo as AttachmentPointInfo).placementId,
            childIds: (selectedInfo as AttachmentPointInfo).childIds,
            isEditButton: false,
          };
          callback = this._selectAttachmentPoint;
          break;
        case EditPointInfo:
          expectedUserData = {
            modulePlacementId: (selectedInfo as EditPointInfo).placementId,
            isEditButton: true,
          };
          callback = this._selectEditPoint;
          break;
        default:
          this._loggingService.debug('Something strange was selected');
      }

      if (callback && expectedUserData) {
        this._topSceneSpriteGroup?.traverse((x) => {
          if (
            x instanceof Sprite &&
            SpriteData.spriteHasData(x) &&
            Object.keys(expectedUserData).every(key => SpriteData.fromSprite(x)[key]?.toString() === expectedUserData[key]?.toString())
            //toString ensures array equality
          ) {
            callback.bind(this)(x);
            returnVal = x;
          }
        });
      }
    }

    // handle situation where deletion results in an AP disappearing
    // for now, simply go to 'nothing selected' screen
    if (!returnVal) {
      this.clickedAttachmentPoint.emit({info: null, config: null});
      this.unsetSelectedPoints();
    }

    return returnVal;
  }

  private _getRenderState(): RenderState {
    return new RenderState(
      this._activeCamera.position,
      this._activeCamera.rotation,
      this._controls.target,
    );
  }

  /**
   * Set up the floor as a two flat square planes (one for color and one for shadows) and rotate accordingly.
   *
   * @private
   */
  private _setUpFloor(color: ColorRepresentation, useTextures: boolean): void {
    const floor_material = this._modelLoadingService.floorMaterial;
    const floor_geometry = new PlaneGeometry(this._floorSize, this._floorSize);
    if (useTextures && this._modelLoadingService.floorTexture) {
      floor_material.map.repeat = new Vector2(20, 20);
      floor_material.map.wrapT = RepeatWrapping;
      floor_material.map.wrapS = RepeatWrapping;
      this._setFloorAndShadow(floor_material, floor_geometry, useTextures);
    } else {
      const floor_material_edit = new MeshBasicMaterial({color});
      this._setFloorAndShadow(floor_material_edit, floor_geometry, useTextures);
    }
  }

  private _setFloorAndShadow(
    floorMaterial: MeshStandardMaterial | MeshBasicMaterial,
    floorGeometry: PlaneGeometry,
    useTextures: boolean,
  ): void {
    floorMaterial.depthWrite = false;

    const floor = new Mesh(floorGeometry, floorMaterial);
    floor.name = 'floor';
    floor.castShadow = false;
    floor.receiveShadow = false;
    floor.rotation.x -= Math.PI / 2;
    this._floor = floor;

    // Add a separate transparent plane that can receive the cast shadows, so the actual floor's colors are not affected by light
    const shadow_floor_geometry = new PlaneGeometry(this._floorSize, this._floorSize);
    const shadow_floor_material = new ShadowMaterial({opacity: (useTextures && this._modelLoadingService.floorTexture) ? 0.3 : 0.2});
    shadow_floor_material.depthWrite = false;
    const shadow_floor = new Mesh(shadow_floor_geometry, shadow_floor_material);
    shadow_floor.name = 'shadowfloor';
    shadow_floor.castShadow = false;
    shadow_floor.receiveShadow = true;
    shadow_floor.rotation.x -= Math.PI / 2;
    this._shadowFloor = shadow_floor;
  }

  /**
   * Set up the background cylinder.
   *
   * @private
   */
  private _setUpCylinder(fromGradient: ColorRepresentation, toGradient: ColorRepresentation, useTextures: boolean): void {
    const cylinder_material = this._modelLoadingService.skyMaterial;
    const cylinder_texture = this._modelLoadingService.skyTexture;
    const height = useTextures && cylinder_texture ? 18 : 5;
    const cylinder_geometry = new CylinderGeometry(
      this._maxCameraDistance * this._cylinderFactor,
      this._maxCameraDistance * this._cylinderFactor,
      height, 50, 1, true,
    );
    cylinder_geometry.computeBoundingBox();
    if (useTextures && cylinder_texture) {
      cylinder_material.side = DoubleSide;
      this._setCylinder(cylinder_geometry, cylinder_material, height);
    } else {
      const cylinder_material_edit = new ShaderMaterial({
        uniforms: {
          color1: {value: new Color(fromGradient)},
          color2: {value: new Color(toGradient)},
          ratio: {value: 1},
        },
        side: DoubleSide,
        vertexShader: `
          varying vec2 vUv;
      
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform vec3 color1;
          uniform vec3 color2;
      
          varying vec2 vUv;
      
          void main() {
            gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
          }
        `,
      });
      this._setCylinder(cylinder_geometry, cylinder_material_edit, height);
    }
  }

  private _setCylinder(cylinderGeometry: CylinderGeometry, cylinderMaterial: MeshStandardMaterial | ShaderMaterial, height: number): void {
    this._cylinder = new Mesh(cylinderGeometry, cylinderMaterial);
    this._cylinder.name = 'cylinder';
    this._cylinder.position.setY(height / 2);
  }

  private _setUpGrass(): void {
    const configObject: Box3 = this.getTotalObjectBox();
    const configCylinder: Sphere = this._cylinder.geometry.boundingSphere;
    this._grass = [];
    this._createGrassPlacements(this._modelLoadingService.grass, this._grass, this._GRASS_AMOUNT, configCylinder, configObject);
    // add grass to the scene
    if (this._grass.length) {
      this._scene.add(...this._grass);
    }
  }

  private _createGrassPlacements(
    grassGroup: Group,
    grass: Group[],
    grassAmount: number,
    configCylinder: Sphere,
    configObject: Box3,
  ): void {
    if (grassAmount > 0) {
      const newGrassAmount: number = --grassAmount;
      const newGrass = grassGroup.clone(true);
      this._createGrassPosition(configCylinder.getBoundingBox(new Box3()), configObject, newGrass);
      newGrass.name = 'background'; // grass is part of the background
      grass.push(newGrass);
      this._createGrassPlacements(grassGroup, grass, newGrassAmount, configCylinder, configObject);
    }
  }

  private _createGrassPosition(configCylinder: Box3, configObject: Box3, grassGroup: Group): void {
    const boundingBoxCylinder = {
      minX: configCylinder.min.x,
      minZ: configCylinder.min.z,
      maxX: configCylinder.max.x,
      maxZ: configCylinder.max.z,
    };

    const rx = this._getRandomNumber(boundingBoxCylinder.minX, boundingBoxCylinder.maxX);
    const rz = this._getRandomNumber(boundingBoxCylinder.minZ, boundingBoxCylinder.maxZ);
    grassGroup.position.set(rx, configObject.min.y, rz);
  }

  private _setUpTrees(): void {
    const configObject: Box3 = this.getTotalObjectBox();
    const configCylinder: Sphere = this._cylinder.geometry.boundingSphere;
    this._trees = []; // reset trees
    // create trees
    this._createTreePlacements(this._modelLoadingService.treeGlb1, this._trees, this._TREE_AMOUNT,
      this._TREE_SCALE, configObject, configCylinder,
    );
    this._createTreePlacements(this._modelLoadingService.treeGlb2, this._trees, this._TREE_AMOUNT,
      this._TREE_SCALE, configObject, configCylinder,
    );
    // add trees to the scene
    if (this._trees.length) {
      this._scene.add(...this._trees);
    }
  }

  private _createTreePlacements(
    treeGroup: Group,
    trees: Group[],
    treeAmount: number,
    treeScale: number,
    configObject: Box3, configCylinder: Sphere,
  ): void {
    if (treeAmount > 0) {
      let newTreeAmount: number = --treeAmount;
      const newTree = treeGroup.clone(true);
      this._createPosition(configObject, configCylinder.getBoundingBox(new Box3()), newTree, treeAmount);
      newTree.scale.set(treeScale, treeScale, treeScale);
      newTree.name = 'background'; // tree is part of the background
      if (this._isInsideObjectBox(newTree, configObject, configCylinder)) {
        trees.push(newTree);
      } else {
        newTreeAmount += 1;
      }
      this._createTreePlacements(treeGroup, trees, newTreeAmount, treeScale, configObject, configCylinder);
    }
  }

  private _createPosition(configObject: Box3, configCylinder: Box3, tree: Group, treeAmount: number): void {
    const boundingBoxObject = {
      minX: configObject.min.x,
      minZ: configObject.min.z,
      maxX: configObject.max.x,
      maxZ: configObject.max.z,
    };
    const boundingBoxCylinder = {
      minX: configCylinder.min.x,
      minZ: configCylinder.min.z,
      maxX: configCylinder.max.x,
      maxZ: configCylinder.max.z,
    };

    if (treeAmount > this._TREE_AMOUNT / 1.333) {
      const rx = this._getRandomNumber(boundingBoxCylinder.minX, boundingBoxObject.minX);
      const rz = this._getRandomNumber(boundingBoxObject.minZ, boundingBoxCylinder.maxZ);
      tree.position.set(rx, configObject.min.y, rz);
    } else if (treeAmount > this._TREE_AMOUNT / 2) {
      const rx = this._getRandomNumber(boundingBoxObject.minX, boundingBoxCylinder.maxX);
      const rz = this._getRandomNumber(boundingBoxObject.maxZ, boundingBoxCylinder.maxZ);
      tree.position.set(rx, configObject.min.y, rz);
    } else if (treeAmount > this._TREE_AMOUNT / 4) {
      const rx = this._getRandomNumber(boundingBoxObject.maxX, boundingBoxCylinder.maxX);
      const rz = this._getRandomNumber(boundingBoxCylinder.minZ, boundingBoxObject.maxZ);
      tree.position.set(rx, configObject.min.y, rz);
    } else {
      const rx = this._getRandomNumber(boundingBoxCylinder.minX, boundingBoxObject.maxX);
      const rz = this._getRandomNumber(boundingBoxCylinder.minZ, boundingBoxObject.minZ);
      tree.position.set(rx, configObject.min.y, rz);
    }
  }

  private _setSkyboxTexture(): void {
    if (!this._skyboxTexture) {
      this._skyboxTexture = new RGBELoader().load(this._skyboxTextureLocation);
    }
  }

  public setImageBackground(): void {
    console.log('EXPERIMENTAL: Loading garden texture');
    this._setSkyboxTexture();
    this._skybox = new GroundedSkybox(this._skyboxTexture, this._skyboxParams.height, this._skyboxParams.radius);
    this._skybox.position.y = this._skyboxParams.height - 0.01;
    this._backgroundGroup.add( this._skybox );
  }

  public setBackground(
    fromGradient: ColorRepresentation,
    toGradient: ColorRepresentation,
    backgroundColor: ColorRepresentation,
    useTextures: boolean = false,
  ): void {
    this._backgroundGroup.clear();
    if (this._dataService.currentScope === '007') {
      this.setImageBackground();
    } else {
      this._setUpFloor(backgroundColor ?? 0xffffff, useTextures);
      this._setUpCylinder(
        fromGradient ?? 0xffffff,
        toGradient ?? 0xffffff,
        useTextures,
      );
      this._scene.environment = null;
      this._scene.background = new Color(toGradient);
      this._backgroundGroup.add(this._floor, this._shadowFloor, this._cylinder);
    }

  }

  public setFavicon(
    faviconUrl: string,
  ): void {
    const linkTag: HTMLLinkElement = document.querySelector('link[rel*=\'icon\']');
    linkTag.rel = 'shortcut icon';
    linkTag.href = faviconUrl;
    linkTag.crossOrigin = 'anonymous';
    document.head.appendChild(linkTag);
  }

  /**
   * Set up the ambient light that makes the whole scene brighter and the directional light to cast the shadows.
   *
   * @private
   */
  private _setUpLights(): void {
    // Note; always create the helpers after setting all the parameters (somewhere a deep copy of some properties is used)

    const shadowCameraOffset = Math.pow(2, 4); // 32
    const shadowMapSize = Math.pow(2, 12); // 4096

    // General hemisphere light
    const light1: HemisphereLight = new HemisphereLight('#ffffff', '#f2ffd1', 2.356);
    light1.position.set(0, 10, 0);

    // Directional ambient light
    const light2: DirectionalLight = new DirectionalLight('#ffffff', 0.94);
    light2.position.set(83, 270, 6);

    // Shadow light
    const light3: DirectionalLight = new DirectionalLight('#ffddc9', 0.314);
    light3.castShadow = true;
    light3.position.set(-5, 25, 5);
    light3.shadow.bias = -0.0001; // Needed to make shadow not display as many stripes on objects
    light3.shadow.camera.left = -shadowCameraOffset;
    light3.shadow.camera.right = shadowCameraOffset;
    light3.shadow.camera.top = shadowCameraOffset;
    light3.shadow.camera.bottom = -shadowCameraOffset;
    light3.shadow.mapSize.set(shadowMapSize, shadowMapSize);

    this._lights = [light1, light2, light3];
    this._lightHelpers = []; // Overwritten (possibly) by code below

    // // For debugging, helps with view of the camera positions
    // const light1_helper: HemisphereLightHelper = new HemisphereLightHelper(light1, 5, new Color(255, 0, 0));
    // const light2_helper: DirectionalLightHelper = new DirectionalLightHelper(light2, 5, new Color(0, 255, 0));
    // const light3_helper: DirectionalLightHelper = new DirectionalLightHelper(light3, 5, new Color(0, 0, 255));
    // const light3_helper_shadow_camera: CameraHelper = new CameraHelper(light3.shadow.camera);
    //
    // this._lightHelpers = [light1_helper, light2_helper, light3_helper, light3_helper_shadow_camera];
    //
    // // For debugging purposes, adds a UI for setting some settings
    // this._gui = new GUI();
    //
    // const hemFolder = this._gui.addFolder('Hemisphere light');
    // hemFolder.add(light1, 'intensity').name("Intensity");
    // const hemisphereLightParams = {hemisphereLightColor: light1.color.getHex()};
    // hemFolder.addColor(hemisphereLightParams, 'hemisphereLightColor').name("Color")
    //   .onChange(value => light3.color.set(value));
    // hemFolder.open();
    //
    // const ambFolder = this._gui.addFolder('Ambient light');
    // ambFolder.add(light2, 'intensity').name("Intensity");
    // const ambientLightParams = {ambientLightColor: light2.color.getHex()};
    // ambFolder.addColor(ambientLightParams, 'ambientLightColor').name("Color")
    //   .onChange(value => light3.color.set(value));
    // ambFolder.open();
    //
    // const shaFolder = this._gui.addFolder('Shadow light');
    // shaFolder.add(light3, 'intensity').name("Intensity");
    // const shadowLightParams = {shadowLightColor: light3.color.getHex()};
    // shaFolder.addColor(shadowLightParams, 'shadowLightColor').name("Color")
    //   .onChange(value => light3.color.set(value));
    // shaFolder.open();
  }

  /**
   * Update the camera using the configured camera position from the config.
   */
  private _setCameraConfig(config: Configuration): void {
    if (config?.renderState) {
      if (config.renderState.cameraPosition) {
        this._activeCamera.position.set(
          config.renderState.cameraPosition.x,
          config.renderState.cameraPosition.y,
          config.renderState.cameraPosition.z,
        );
      }
      if (config.renderState.cameraRotation) {
        // Note: this euler should always have the same order (-> XYZ)
        this._activeCamera.rotation.set(
          config.renderState.cameraRotation.x,
          config.renderState.cameraRotation.y,
          config.renderState.cameraRotation.z,
        );
      }
      if (config.renderState.controlTarget) {
        this._controls.target.set(
          config.renderState.controlTarget.x,
          config.renderState.controlTarget.y,
          config.renderState.controlTarget.z,
        );
      }
      this._activeCamera.updateProjectionMatrix();
    } else {
      this._activeCamera.position.set(0, 5, 15);
      this._activeCamera.rotation.set(0, 0, 0);
      this._controls.target.set(0, 0, 0);
    }
  }

  /**
   * Helper function to completely clear the scene
   *
   * @private
   */
  private _clearScene() {
    if (this._loadedConfigurationGroup && this._scene) {
      this._loadedConfigurationGroup.traverse(ModelLoadingService.disposeNodeAttributes); // dispose textures and geometries
      ModelLoadingService.clearObjectTree(this._loadedConfigurationGroup); // clear object references
      this._scene.remove(this._loadedConfigurationGroup);
    }
    if (this._loadedMarkerGroup && this._scene) {
      this._loadedMarkerGroup.traverse(ModelLoadingService.disposeNodeAttributes); // dispose textures and geometries
      ModelLoadingService.clearObjectTree(this._loadedMarkerGroup); // clear object references
      this._scene.remove(this._loadedMarkerGroup);
    }
    if (this._topSceneSpriteGroup && this._loadedSpriteGroup && this._topScene) {
      this._topSceneSpriteGroup.traverse(ModelLoadingService.disposeNodeAttributes); // dispose textures and geometries
      this._loadedSpriteGroup.traverse(ModelLoadingService.disposeNodeAttributes); // dispose textures and geometries
      ModelLoadingService.clearObjectTree(this._topSceneSpriteGroup); // clear object references
      ModelLoadingService.clearObjectTree(this._loadedSpriteGroup); // clear object references
      this._topScene.remove(this._topSceneSpriteGroup);
      this._scene.remove(this._loadedSpriteGroup);
    }
  }

  /**
   * Corrects the camera position based on the freedom sphere.
   * Limits both zooming and panning of the camera.
   * Note that this multiplies vectors by the difference in distance to get it to the desired length
   */
  private _limitPosition(): void {
    if (!this._arActive) {
      const position = this._activeCamera.position;
      if (!this._freedomSphere.containsPoint(position)) {
        const actualDistance = position.distanceTo(this._freedomSphereCenter);
        const newPosition = position.multiplyScalar(this._maxCameraDistance / actualDistance);
        this._activeCamera.position.set(newPosition.x, newPosition.y, newPosition.z);
      }
      // Limit new camera position to 0.1 to keep it off the floor
      if (this._activeCamera.position.y < 0.1) {
        this._activeCamera.position.setY(0.1);
      }
    }
  }

  /**
   * Handles click events
   *
   * @private
   */
  private _onClick(event: MouseEvent): void {
    // Find where in the three.js canvas the user has clicked
    const canvasSize = this._renderer.domElement.getBoundingClientRect();
    this._pointer.x = ((event.clientX - canvasSize.left) / (canvasSize.right - canvasSize.left)) * 2 - 1;
    this._pointer.y = -((event.clientY - canvasSize.top) / (canvasSize.bottom - canvasSize.top)) * 2 + 1;

    // Fire a ray from the clicked position into the scene and see what it intersects
    this._rayCaster.setFromCamera(this._pointer, this._topCamera);
    const intersects = this._rayCaster.intersectObjects(this._topScene.children)
      .filter((obj) => obj.object.userData[DEBUG] === undefined);

    // If the cast ray has intersected something, take the first object in line and handle the click
    if (intersects.length > 0) {
      const clickedObject = intersects[0].object;
      this._handleObjectClick(clickedObject);
    }

    // DEBUG: update renderInfoSubject
    this.renderInfo.next({
      memory: this._renderer.info.memory,
      render: this._renderer.info.render,
    });

    this.refreshScene();
  }

  /**
   * Trigger animation when using the orbit control
   *
   * @private
   */
  private _setUpControlListener(): void {
    // start animating
    this._controls.addEventListener('start', () => this.animating.next(true));
    this._controls.addEventListener('end', () => this.animating.next(false));
    // detects any change in controls and fires the position limiter
    this._controls.addEventListener('change', this._limitPosition.bind(this));
  }

  /**
   * Triggers a single animation 'ping' - for resize
   *
   * @private
   */
  private _triggerSingleAnimationPing(_: MouseEvent): void {
    // animation single ping if not already animating
    if (!this.animating.getValue()) {
      this.animating.next(true);
      this.animating.next(false);
    }
  }

  /**
   * Handle any click in the three.js canvas according to what was clicked
   *
   * @private
   */
  private _handleObjectClick(clickedObject: Object3D): Object3D {
    // name condition is a quick fix for the text sprites. If more sprites are introduced, this should be more generic
    if (clickedObject instanceof Sprite && clickedObject.name !== 'text') {
      // first, deselect everything
      this.deselectPoints();

      // then, select new object if any
      if (SpriteData.fromSprite(clickedObject)?.isEditButton) {
        this._selectEditPoint(clickedObject, true);
      } else {
        this._selectAttachmentPoint(clickedObject, true);
      }
    }
    return clickedObject;
  }

  public deselectPoints(): void {
    if (this._selectedEditPoint?.id) {
      this._attachmentPointService.deselectSprite(this._selectedEditPoint);
    }
    if (this._selectedAttachmentPoint?.id) {
      this._attachmentPointService.deselectSprite(this._selectedAttachmentPoint);
    }
    this.clickedAttachmentPoint.emit({info: null, config: null});
    this.unsetSelectedPoints();
  }

  /**
   * Select Edit Point: set proper sprite, set data, emit on EventEmitter
   *
   * @param editPoint The edit point to select
   * @param logClickGA Optional flag to log this click to google tag manager or not
   * @private
   * */
  private _selectEditPoint(editPoint: Sprite, logClickGA: boolean = false) {
    this.spinnerOnSubject$.next();
    this._attachmentPointService.selectSprite(editPoint);
    this._selectedEditPoint = editPoint;
    this.clickedEditPoint.emit({
      info: this.getSelectedEditPointInfo(),
      config: this._loadedConfiguration,
      logClickGA: logClickGA,
    });
    this.spinnerOffSubject$.next();
  }

  /**
   * Select Attachment Point: set proper sprite, set data, emit on EventEmitter
   *
   * @param attachmentPoint The attachment point to select
   * @param logClickGA Optional flag to log this click to google tag manager or not
   * @private
   */
  private _selectAttachmentPoint(attachmentPoint: Sprite, logClickGA: boolean = false) {
    this.spinnerOnSubject$.next();
    this._attachmentPointService.selectSprite(attachmentPoint);
    this._selectedAttachmentPoint = attachmentPoint;
    this.clickedAttachmentPoint.emit({
      info: this.getSelectedAttachmentPointInfo(),
      config: this._loadedConfiguration,
      logClickGA: logClickGA
    });
    this.spinnerOffSubject$.next();
  }

  /**
   * Fits the configuration to the view of the camera, for which it uses the box around the configuration passed to the function.
   * For different kinds of screenshots it fits the configuration differently.
   * Note; this function is fiddly and is made specifically for the orthographic camera. For perspective cameras, this does not work like this.
   */
  public fitCameraToConfiguration(totalBox: Box3, viewMode?: ViewMode): void {
    // Determine model center, sizes and ratios
    let totalBoxCenter = new Vector3();
    totalBox.getCenter(totalBoxCenter);
    let totalBoxSize = new Vector3();
    totalBox.getSize(totalBoxSize);
    const renderSize = new Vector2();
    this._renderer.getSize(renderSize);
    const screenRatio = renderSize.x / renderSize.y;

    // Helpful debugging sphere at center of model (should appear in center of screen)
    // const centerSphere = new Mesh(new SphereGeometry(0.1), new MeshBasicMaterial({color: 0x0000ff}));
    // centerSphere.position.set(center.x, center.y, center.z);
    // this._scene.add(centerSphere);

    // For debugging also show the complete bounding box and the axes
    // const box = new BoxHelper(this._scene, 0x0000ff);
    // this._scene.add(box);
    // this._scene.add(new AxesHelper(5).setColors(new Color(1, 0, 0), new Color(0, 1, 0), new Color(0, 0, 1)));

    switch (viewMode ?? this._viewMode) {
      case ViewMode.cleanTop:
        // Use orthographic camera
        this._setupCameraAndControls(true);
        // First set position (the _maxCameraDistance is arbitrary as only the ratios matter as long as we stay in the cylinder)
        this._orthographicCamera.position.set(totalBoxCenter.x, totalBoxCenter.y + this._maxCameraDistance, totalBoxCenter.z);
        this._orthographicCamera.lookAt(totalBoxCenter);
        const curRot = this._orthographicCamera.rotation;
        // Always make the longest side the front side
        if (totalBoxSize.x > totalBoxSize.z) {
          this._determineCameraPosition(totalBoxSize.z, totalBoxSize.x, screenRatio, this._screenshotClearing, this._orthographicCamera);
        } else {
          this._orthographicCamera.rotation.set(curRot.x, curRot.y, curRot.z + (Math.PI / 2));
          this._determineCameraPosition(totalBoxSize.x, totalBoxSize.z, screenRatio, this._screenshotClearing, this._orthographicCamera);
        }
        this._controls.reset();
        break;

      case ViewMode.cleanFront:
        this._controls.reset();
        // Use orthographic camera
        this._setupCameraAndControls(true);
        //Always make the longest side the front side
        if (totalBoxSize.x > totalBoxSize.z) {
          this._orthographicCamera.position.set(totalBoxCenter.x, totalBoxCenter.y, totalBoxCenter.z + this._maxCameraDistance);
          this._orthographicCamera.lookAt(totalBoxCenter);
          this._determineCameraPosition(totalBoxSize.y, totalBoxSize.x, screenRatio, this._screenshotClearing, this._orthographicCamera);
        } else {
          // First set position (the _maxCameraDistance is arbitrary as only the ratios matter as long as we stay in the cylinder)
          this._orthographicCamera.position.set(totalBoxCenter.x + this._maxCameraDistance, totalBoxCenter.y, totalBoxCenter.z);
          this._orthographicCamera.lookAt(totalBoxCenter);
          this._determineCameraPosition(totalBoxSize.y, totalBoxSize.z, screenRatio, this._screenshotClearing, this._orthographicCamera);
        }
        break;

      case ViewMode.cleanSide:
        this._controls.reset();
        // Use orthographic camera
        this._setupCameraAndControls(true);
        //Always make the longest side the front side
        if (totalBoxSize.x > totalBoxSize.z) {
          // First set position (the _maxCameraDistance is arbitrary as only the ratios matter as long as we stay in the cylinder)
          this._orthographicCamera.position.set(totalBoxCenter.x - this._maxCameraDistance, totalBoxCenter.y, totalBoxCenter.z);
          this._orthographicCamera.lookAt(totalBoxCenter);
          this._determineCameraPosition(totalBoxSize.y, totalBoxSize.x, screenRatio, this._screenshotClearing, this._orthographicCamera);
        } else {
          // First set position (the _maxCameraDistance is arbitrary as only the ratios matter as long as we stay in the cylinder)
          this._orthographicCamera.position.set(totalBoxCenter.x, totalBoxCenter.y, totalBoxCenter.z + this._maxCameraDistance);
          this._orthographicCamera.lookAt(totalBoxCenter);
          this._determineCameraPosition(totalBoxSize.y, totalBoxSize.x, screenRatio, this._screenshotClearing, this._orthographicCamera);
        }
        break;

      case ViewMode.cleanNice:
        // If the config is longer than it is wide we rotate it 90 degrees to fit better
        if (totalBoxSize.x < totalBoxSize.z) {
          this._loadedConfigurationGroup.rotateY(Math.PI / 2);

          // Reset size and center because the supplied totalBox will be wrong now
          const newTotalBox = this.getTotalObjectBox();
          totalBoxCenter = new Vector3();
          newTotalBox.getCenter(totalBoxCenter);
          totalBoxSize = new Vector3();
          newTotalBox.getSize(totalBoxSize);
        }

        // Draw a box around the configuration, to be uncommented for debugging purposes
        // const configBoxGeometry = new BoxGeometry(totalBoxSize.x, totalBoxSize.y, totalBoxSize.z);
        // const configBoxMesh = new Mesh(configBoxGeometry, this._debugService.generateMaterial(ColorType.Purple));
        //
        // configBoxMesh.position.set(totalBoxCenter.x, totalBoxSize.y / 2, totalBoxCenter.z);
        // configBoxMesh.userData = {[DEBUG]: 1}; // debug objects are not used in raytracing
        // this._backgroundGroup.add(configBoxMesh);

        // Scale the configuration so that it fits into a predefined ideal box
        const idealBoxDimensions = new Vector3(6, 3, 4);
        const ratios = [
          totalBoxSize.x / idealBoxDimensions.x,
          totalBoxSize.y / idealBoxDimensions.y,
          totalBoxSize.z / idealBoxDimensions.z,
        ];
        const maxRatio = Math.max(...ratios);
        const scaleFactor = 1 / maxRatio;

        // Calculate the config center after scaling to use later
        const configCenterAfterScaling = new Vector3(totalBoxCenter.x * scaleFactor, idealBoxDimensions.y * scaleFactor, totalBoxCenter.z * scaleFactor);

        // Draw box for the ideal box we want to fit the config into, to be uncommented for debugging purposes
        // const idealBoxGeometry = new BoxGeometry(idealBoxDimensions.x, idealBoxDimensions.y, idealBoxDimensions.z);
        // const idealBoxMesh = new Mesh(idealBoxGeometry, this._debugService.generateMaterial(ColorType.Orange));
        //
        // idealBoxMesh.position.set(configCenterAfterScaling.x, idealBoxDimensions.y / 2, configCenterAfterScaling.z);
        // idealBoxMesh.userData = {[DEBUG]: 1}; // debug objects are not used in raytracing
        // this._backgroundGroup.add(idealBoxMesh);

        const scaleMatrix = new Matrix4().makeScale(scaleFactor, scaleFactor, scaleFactor);
        this._loadedConfigurationGroup.applyMatrix4(scaleMatrix);

        // Move config to the front of the ideal box to be as close as possible
        const offsetInBox = new Vector3(
          0,
          0,
          (idealBoxDimensions.z - (totalBoxSize.z * scaleFactor)) / 2,
        );
        this._loadedConfigurationGroup.position.add(offsetInBox);

        // Position the camera so that it looks directly at the box
        this._perspectiveCamera.position.set(configCenterAfterScaling.x, 0, configCenterAfterScaling.z)
          .add(new Vector3(4.4, 1.5, 5.4));
        this._controls.target.set(configCenterAfterScaling.x, 1.25, configCenterAfterScaling.z);
        break;
      default:
        break;
    }

    // Finally update camera and controls. Now we are done.
    this._activeCamera.updateProjectionMatrix();
    this._controls.update();
    this._loggingService.debug(`RenderService:: ViewMode setting complete (${viewMode ?? this._viewMode})`);
  }

  /**
   * Helper to determine cameraposition.
   * Note: camera is modified!
   */
  private _determineCameraPosition(sizeTopBot: number, sizeLR: number, ratio: number, clearing: number, camera: OrthographicCamera): OrthographicCamera {
    // Determine the ratio of the box size for this view (length / height) and the corresponding multiplier
    //const boxRatioSide = size.x / size.y;
    const boxRatioSide = sizeLR / sizeTopBot;
    const multiplierSide = boxRatioSide / ratio;

    // if the screen 'taller' than the configuration, make the camera angle wider from top to bottom
    // if the screen is 'smaller' than the configuration, make the camera angle wider from left to right
    camera.top = clearing + Math.max(1, multiplierSide) * sizeTopBot / 2;
    camera.bottom = -(clearing + Math.max(1, multiplierSide) * sizeTopBot / 2);
    camera.left = -(clearing + sizeLR / (2 * Math.min(1, multiplierSide)));
    camera.right = clearing + sizeLR / (2 * Math.min(1, multiplierSide));
    return camera;
  }

  /**
   * Returns the total box around the scene.
   */
  public getTotalObjectBox(): Box3 {
    // Copy the scene
    const gridCopy = this._scene.clone(true);
    // Now remove all objects that do not add to the size (sprites and grid)
    // (sprites are the attachment points)
    const objectsToRemove: Object3D[] = [];
    gridCopy.traverse(o => {
      // Add to array to remove later to avoid null pointer exceptions
      if ((o instanceof Sprite) || (o instanceof Group && (o.name === 'background'))) {
        objectsToRemove.push(o);
      }
    });
    // Now remove all of them
    objectsToRemove.forEach(o => o.removeFromParent());

    // Create a box around everything en return it
    return new Box3().setFromObject(gridCopy);
  }

  // used to sanitize translations with HTML code included. Potential scripts will be removed
  public sanitizeString(value: string): SafeHtml {
    return this._sanitizer.sanitize(SecurityContext.HTML, value);
  }

  public fixUTF8WithHtml(value: string): string {
    const element = document.createElement('p');
    element.innerHTML = value;
    return element.innerHTML;
  }

  private _isInsideObjectBox(tree: Group, configObject: Box3, configCylinder: Sphere): boolean {
    return !configObject.containsPoint(tree.position) &&
      configObject.distanceToPoint(tree.position) > this._OFFSET &&
      configCylinder.containsPoint(tree.position);
  }

  private _getRandomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }
}
